<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from '../../store';

const store = useStore();

const invoice = computed(() => store.state.chorus.selected[0]);
</script>

<template>
  <div class="pt-8 bg-blue-light mt-8 h-full">
    <div class="space-y-5">
      <div class="w-full border-b-2 border-blue-text text-blue-text">
        Détails
      </div>
      <p class="font-medium">
        {{
          invoice.recipient
            ? invoice.recipient.name
            : "Aucun destinataire"
        }}
      </p>
      <p class="font-medium">
        {{ invoice.title }}
      </p>
      <div class="flex justify-between">
        <div>
          <p class="text-gray-500">
            Numéro de commande
          </p>
          <p class="font-medium">
            {{ invoice.commitmentNumber ? invoice.commitmentNumber : 'Aucun numéro de commande' }}
          </p>
        </div>
        <div>
          <p class="text-gray-500">
            Numéro de marché
          </p>
          <p class="font-medium">
            {{ invoice.contractNumber ? invoice.contractNumber : 'Aucun numéro de marché' }}
          </p>
        </div>
      </div>
      <div class="flex justify-between">
        <div>
          <p class="text-gray-500">
            Total HT
          </p>
          <p class="font-medium">
            {{ invoice.totalNet.toFixed(2) }} €
          </p>
        </div>
        <div>
          <p class="text-gray-500">
            Total TVA
          </p>
          <p class="font-medium">
            {{ invoice.totalTax.toFixed(2) }} €
          </p>
        </div>
        <div>
          <p class="text-gray-500">
            Total TTC
          </p>
          <p class="font-medium">
            {{ invoice.total.toFixed(2) }} €
          </p>
        </div>
      </div>
    </div>
    <div class="mt-12">
      <div class="w-full border-b-2 border-blue-text text-blue-text">
        Historique
      </div>
      <div
        v-if="invoice.verifiedAt"
        class="text-sm py-2 mt-4 border-b-2 border-white"
      >
        Cette facture à été contrôlée le {{ new Date(invoice.verifiedAt)
          .toLocaleDateString()
          .replaceAll("/", "-") }} par {{ invoice.verifiedBy?.familyName + ' ' + invoice.verifiedBy?.givenName }}
      </div>
      <div
        v-if="invoice.createdAt"
        class="text-sm py-2"
      >
        Cette facture à été créée le {{ new Date(invoice.createdAt)
          .toLocaleDateString()
          .replaceAll("/", "-") }} par {{ invoice.createdBy?.familyName + ' ' + invoice.createdBy?.givenName }}
      </div>
    </div>
  </div>
</template>
