<template>
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0105 7.01981V9.01585M10.0105 13.0079V13.0179M3.00781 17H17.0133C17.3397 16.9977 17.6606 16.9158 17.948 16.7613C18.2354 16.6069 18.4806 16.3847 18.6621 16.114C18.8436 15.8433 18.956 15.5325 18.9895 15.2085C19.0229 14.8845 18.9764 14.5573 18.854 14.2554L11.7512 2.02971C11.5782 1.71773 11.3245 1.45768 11.0167 1.27659C10.7088 1.09551 10.3579 1 10.0005 1C9.64313 1 9.29225 1.09551 8.98438 1.27659C8.67651 1.45768 8.42287 1.71773 8.24985 2.02971L1.14708 14.2554C1.02698 14.5504 0.979351 14.8698 1.00818 15.1869C1.03701 15.504 1.14145 15.8096 1.3128 16.0782C1.48414 16.3469 1.71742 16.5707 1.99314 16.731C2.26886 16.8914 2.57904 16.9836 2.89776 17"
      stroke="currentColor"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
