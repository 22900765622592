import { createI18n, useI18n as BaseUseI18n } from 'vue-i18n';
import fr from './locales/fr.json';

export const i18n = createI18n({
  legacy: false,
  locale: 'fr',
  messages: {
    fr,
  },
});

export function useI18n() {
  return BaseUseI18n();
}
