import { defineStore } from 'pinia';
import { ref } from 'vue';

type FlashMessageType = 'error' | 'warning' | 'info' | 'success';
type ActionType = () => void;

const useFlashMessage = defineStore('flash-message', () => {
  const msg = ref<string | null>(null);
  const type = ref<FlashMessageType>('error');
  const action = ref<ActionType>(() => { });
  const btn = ref<string | null>(null);
  const visible = ref<boolean>(false);

  const clear = () => {
    visible.value = false;
  };

  const show = (t: FlashMessageType, m: string, b?: string, a?: ActionType, delay: number | false = 10000) => {
    msg.value = m;
    type.value = t;
    action.value = a || (() => { });
    btn.value = b || null;
    visible.value = true;

    if (delay === false) return;

    setTimeout(() => {
      clear();
    }, delay);
  };

  const error = (message: string, b?: string, a?: ActionType) => {
    show('error', message, b || 'page_refresh', a || (() => { window.location.reload(); }));
  };

  const warning = (message: string, b?: string, a?: ActionType) => {
    show('warning', message, b || 'ok', a || (() => { clear(); }));
  };

  const success = (message: string, b?: string, a?: ActionType) => {
    show('success', message, b || 'ok', a || (() => { clear(); }));
  };

  const info = (message: string, b?: string, a?: ActionType) => {
    show('info', message, b || 'ok', a || (() => { clear(); }));
  };

  return {
    error, warning, success, info, msg, clear, type, btn, action, visible,
  };
});
export default useFlashMessage;
