import { computed, ref } from 'vue';
import ChorusInvoiceSubmissionResponse from '../../models/Chorus/ChorusInvoiceSubmissionResponse';

const state = ref({
  invoices: [
    {
      id: '3fa85f64-5717-4662-b3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F000001',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5717-4562-b3fc-2c962f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: null,
      reference: 'F000002',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture non valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 50,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: ['lines', 'verifiedAt'],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5717-4462-b3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F000003',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (avoir)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-07-11T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: true,
      state: null,
    },
    {
      id: '3fa85f64-5717-4862-b3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-22T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F000004',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture envoyée)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-27T13:40:25.144Z',
      total: 442.2,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: {
        status: 'ACCEPTED',
        message: {
          libelle: 'test',
        },
      },
    },
    {
      id: '3fa85f64-5718-4592-b3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-22T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F000005',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'POLE EMPLOI NORD PAS DE CALAIS',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-27T13:40:25.144Z',
      total: 2012.04,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: {
        status: 'ACCEPTED',
        message: {
          libelle: 'test',
        },
      },
    },
    {
      id: '3fa85f64-5717-5662-b3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'V200001',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'POLE EMPLOI NORD PAS DE CALAIS',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-02-11T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-3717-4662-b3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2020-01-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'V200002',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2020-04-22T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f34-5717-4662-b3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2020-01-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'V200003',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture rejetée)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2020-01-17T13:40:25.144Z',
      total: 1245.65,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: ['file'],
      isCredit: false,
      state: {
        status: 'REJECTED',
        message: {
          libelle: 'Siret du destinataire manquant',
        },
      },
    },
    {
      id: '3fa83f64-5717-4662-b3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2020-01-18T13:40:25.144Z',
      verifiedAt: null,
      reference: 'V200004',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'Société GUDUL Pere et Fils',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 356.65,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: ['SIRET', 'verifiedAt'],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85d64-5717-4662-b3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-04-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'V200005',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'Société GUDUL Père et Fils',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2020-07-17T13:40:25.144Z',
      total: 1245.65,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa8ff64-5717-4662-b3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'V200006',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (avoir valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 148.02,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: true,
      state: null,
    },
    {
      id: '3fa35f64-5717-4662-b3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'V200007',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5717-4662-c3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200007',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5717-4662-a3fc-2c963f66afa6',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200200',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5717-4662-b3fc-2c963f66afa9',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200201',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5717-4662-b3fc-2c963f66af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200202',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5717-4662-b3fc-2c963f63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200203',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5717-4662-b3fc-2c964f63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200204',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5717-4662-b3fc-2c954f63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200206',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5717-4662-b3fc-2c454f63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200207',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5717-4662-33fc-2c454f63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200208',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5717-4651-33fc-2c454f63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200209',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5217-4651-33fc-2c454f63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200210',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85f64-5217-4651-33fc-2c454g63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200211',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa85g64-5217-4651-33fc-2c454g63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200212',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa35g64-5217-4651-33fc-2c454g63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200213',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa45g64-5217-4651-33fc-2c454g63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200214',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa55g64-5217-4651-33fc-2c454g63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200215',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa65g64-5217-4651-33fc-2c454g63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200216',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa67g64-5217-4651-33fc-2c454g63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200217',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3fa67g64-5217-1151-33fc-2c454g63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'F200218',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'AGEFOS (facture valide)',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
    {
      id: '3da67g64-5217-1151-33fc-2c454g63af29',
      title: 'string',
      commitmentNumber: '12345690',
      contractNumber: '65432190',
      lines: [
        {
          description: 'string2',
          amount: 0,
          unitPrice: 0,
          totalPrice: 0,
          tax: 0,
        },
      ],
      dueDate: '2021-08-17T13:40:25.144Z',
      verifiedAt: '2021-08-17T13:40:25.144Z',
      reference: 'A200219',
      createdBy: {
        givenName: 'Tanguy',
        familyName: 'Giton',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      createdAt: '2020-01-18T13:40:25.144Z',
      verifiedBy: {
        givenName: 'Hervé',
        familyName: 'Chapelle',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
      },
      recipient: {
        name: 'POLE EMPLOI NORD PAS DE CALAIS',
        address: {
          streetAddress: 'string',
          postalCode: 'string',
          locality: 'string',
          country: 'string',
        },
        siret: 'teststst',
      },
      date: '2021-08-17T13:40:25.144Z',
      total: 100.0,
      totalNet: 0,
      totalTax: 0,
      tax: 0,
      invalidData: [],
      isCredit: false,
      state: null,
    },
  ],
  totalItems: 29,
});

const getDemoInvoiceList = computed(() => state.value.invoices);

const getDemoTotalItems = computed(() => state.value.totalItems);

function setDemoInvoiceSubmission(
  index: number,
  invoiceState: ChorusInvoiceSubmissionResponse,
) {
  state.value.invoices[index].state = invoiceState;
}

export { getDemoInvoiceList, getDemoTotalItems, setDemoInvoiceSubmission };
