<script setup lang="ts">
import { useWindowSize } from '@vueuse/core';
import { defineAsyncComponent } from 'vue';
import { useConfigurationStore } from '../../stores/configuration';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
});

const configurationStore = useConfigurationStore();
const windowSize = useWindowSize();

const Icon = defineAsyncComponent(() => import(`../Shared/Icon/${props.icon}.vue`));
</script>

<template>
  <div
    v-if="!(windowSize.width.value < 1280 && !configurationStore.leftDrawer)"
    class="h-12 flex items-center rounded-lg px-4 my-0.5 hover:bg-blue-332 hover:text-blue-333 "
    :class="!configurationStore.leftDrawer ? ' justify-center ': ''"
  >
    <Icon class="fixed h-6" />
    <div
      v-if="configurationStore.leftDrawer"
      class="pl-8 leading-6 font-medium text-sm line-clamp-1"
    >
      {{ props.title }}
    </div>
  </div>
</template>
