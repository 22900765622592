import { getDemoInvoiceList } from '../../store/Demo/DemoInvoiceList';
import { getAsync, sleep } from '../apiHelper';
import { getSlug } from '../slugApi';

const endpoint = `${import.meta.env.VITE_BACKEND}/api/choruspro/chorus-invoices`;

// eslint-disable-next-line import/prefer-default-export
export async function getAllInvoicesAsync(pageNb: number, itemPerPage: number, search: string = '', orderDate?: string, orderReference?: string, validFilter?: boolean, dateBefore?: string, dateAfter?: string): Promise<any> {
  if (getSlug() !== 'demo') {
    let url = `${endpoint}?page=${pageNb}&itemsPerPage=${itemPerPage}`;
    if (search) url += `&search=${search}`;
    if (orderDate) url += `&order[date]=${orderDate}`;
    if (orderReference) url += `&order[reference]=${orderReference}`;
    if (validFilter !== undefined) url += `&isValid=${validFilter}`;
    if (dateBefore) url += `&date[before]=${dateBefore}`;
    if (dateAfter) url += `&date[after]=${dateAfter}`;

    return await getAsync(url) as any;
  }
  await sleep(400);
  const filtredResult = getDemoInvoiceList.value.filter((e) => {
    if ((e.recipient.name.toLowerCase().includes(search.toLowerCase()) || e.reference.toLowerCase().includes(search.toLowerCase()) || e.createdBy.familyName.toLowerCase().includes(search.toLowerCase()) || e.createdBy.givenName.toLowerCase().includes(search.toLowerCase()) || e.title.toLowerCase().includes(search.toLowerCase())) && (new Date(e.date) >= new Date(dateAfter as string) && new Date(e.date) <= new Date(dateBefore as string))) {
      if (validFilter !== undefined) {
        if (e.invalidData.length > 0 && !validFilter) return e;
        if (!e.invalidData.length && validFilter) return e;
      } else return e;
    }
    return null;
  });
  if (orderReference) filtredResult.sort((a, b) => ((orderReference === 'ASC' ? (a.reference < b.reference) : (a.reference > b.reference)) ? 1 : -1));
  if (orderDate) filtredResult.sort((a, b) => ((orderDate === 'ASC' ? (a.date < b.date) : (a.date > b.date)) ? 1 : -1));
  const { length } = filtredResult;
  const result = [];
  if (filtredResult.length > 25) {
    while (filtredResult.length > 0) result.push(filtredResult.splice(0, 25));
  } else result.push(filtredResult);
  return { 'hydra:member': result[pageNb - 1], 'hydra:totalItems': length };
}
