import { useWindowSize } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import unleash from '../unleash';
import useUserStore from './user';
import PartnerAppNavigation from '../models/Configuration/PartnerAppNavigation';

export interface Category {
  defaultRouteName: string;
  label: string;
  icon: string;
}

export const useConfigurationStore = defineStore('configuration', () => {
  // CATEGORIES

  const userStore = useUserStore();
  const router = useRouter();

  const listCategories: Record<string, Category> = reactive({
    AgendaUsers: {
      label: 'Agenda',
      icon: 'AgendaIcon',
      defaultRouteName: 'Agenda',
    },
    ProcedureActivities: {
      label: 'Activités (Forms)',
      icon: 'ActivityIcon',
      defaultRouteName: 'ProcedureActivities',
    },
    ComptaChorus: {
      label: 'Facturation',
      icon: 'ComptaIcon',
      defaultRouteName: 'ComptaChorus',
    },
    TeoformResponse: {
      label: 'Forms',
      icon: 'TeoformIcon',
      defaultRouteName: 'TeoformResponse',
    },
    Configuration: {
      label: 'Paramètres',
      icon: 'ParamsIcon',
      defaultRouteName: 'Configuration',
    },
  });

  const categories = computed(() => Object.values(listCategories).filter((category) => {
    const route = router.getRoutes().find((localRoute) => localRoute.name === category.defaultRouteName);
    if (!route) return false;
    if (userStore.roles.includes('ROLE_SAV')) return true;
    if (route.meta.security && !userStore.roles.includes(route.meta.security as string)) return false;
    if (route.meta.featureName && !unleash.isEnabled(route.meta.featureName as string)) return false;

    return true;
  }));

  // LEFT DRAWER

  const leftDrawer = ref<boolean>(false);

  function toggleLeftDrawer(value?: boolean) {
    leftDrawer.value = value !== undefined ? value : !leftDrawer.value;
  }

  const windowSize = useWindowSize();
  const isMobile = computed(() => windowSize.width.value < 768);
  const isTablet = computed(() => windowSize.width.value < 1024);
  const partnerAppNavigation = ref<null | PartnerAppNavigation>(null);
  const isPartnerAppLoading = ref(true);

  return {
    categories, leftDrawer, toggleLeftDrawer, isMobile, partnerAppNavigation, isTablet, isPartnerAppLoading, windowSize,
  };
});
