<script lang="ts" setup>
import { TransitionRoot } from '@headlessui/vue';
import DrawerInvoice from '../Invoice/InvoiceDrawer.vue';
import { useStore } from '../../store';

const store = useStore();
</script>

<template>
  <TransitionRoot
    :show="store.state.config.rightDrawer"
    as="div"
    enter="duration-300 transition ease-linear duration-150 transform"
    enter-from="translate-x-full"
    enter-to="-translate-x-0"
    leave="transition duration-150 ease-linear transform"
    leave-from="-translate-x-0"
    leave-to="translate-x-full"
    class="
      bg-white
      z-50
      shadow-lg
      absolute
      xl:relative
      right-0
      bottom-0
      top-0
      w-full
      md:w-2/5
      flex flex-col
    "
  >
    <div class="grow h-0 overflow-y-auto">
      <DrawerInvoice />
    </div>
  </TransitionRoot>
</template>
