import { computed, ref } from 'vue';

const state = ref({ configurations: [] });

function setDemoChorusConfig(config: any) {
  state.value.configurations = config;
}

const getDemoChorusConfig = computed(() => state.value.configurations);

export {
  setDemoChorusConfig,
  getDemoChorusConfig,
};
