// Import the functions you need from the SDKs you need
import { getAuth } from '@firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = import.meta.env.PROD ? {
  apiKey: 'AIzaSyA_3erO-KVxSwOiuEwf0UarQoSOIP9SZ6o',
  authDomain: 'teoapp-314816.firebaseapp.com',
  projectId: 'teoapp-314816',
  storageBucket: 'teoapp-314816.appspot.com',
  messagingSenderId: '837336045775',
  appId: '1:837336045775:web:b45a8f1f9f8b9cd062ffba',
} : {
  apiKey: 'AIzaSyDhj8m8oPn91lKOYXfIS1o25hkS_7UXJ94',
  authDomain: 'teoapp-dev-611b8.firebaseapp.com',
  projectId: 'teoapp-dev-611b8',
  storageBucket: 'teoapp-dev-611b8.appspot.com',
  messagingSenderId: '539324595147',
  appId: '1:539324595147:web:20e0b7a9bc79b13c5f144e',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const auth = getAuth(app);
