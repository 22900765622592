import { Module } from 'vuex';
import { RootState } from '..';

export interface ConfigurationState {
  rightDrawer: boolean;
}

export const types = {
  // Dispatch

  // Commit
  TOOGLE_DRAWER_RIGHT: 'CONFIG_TOOGLE_DRAWER_RIGHT',
};

export default {
  state: (): ConfigurationState => ({
    rightDrawer: false,
  }),
  mutations: {
    [types.TOOGLE_DRAWER_RIGHT]: (state, value?: boolean) => {
      state.rightDrawer = value !== undefined ? value : !state.rightDrawer;
    },
  },
  actions: {},
} as Module<ConfigurationState, RootState>;
