<script lang="ts" setup>
import { TransitionRoot } from '@headlessui/vue';
import AlertIcon from './Icon/AlertIcon.vue';
import PlusIcon from './Icon/PlusIcon.vue';
import useFlashMessage from '../../stores/flashMessage';
import { useI18n } from '../../i18n';

const flashMessage = useFlashMessage();
const { t } = useI18n();
</script>
<template>
  <TransitionRoot
    :show="flashMessage.visible"
    enter="transition-opacity ease-in-out duration-150"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity ease-linear duration-150"
    leave-from="opacity-100"
    leave-to="opacity-0"
    as="div"
    class="absolute right-6 top-6 ml-6 flex space-x-3 justify-center border-l-[0.313rem] rounded-md p-4 z-[100] shadow-lg sentry-unmask"
    :class="{
      'bg-red-331 border-red-335': flashMessage.type === 'error',
      'bg-green-50 border-green-333': flashMessage.type === 'success',
      'bg-orange-100 border-orange-333': flashMessage.type === 'warning',
      'bg-blue-100 border-blue-333': flashMessage.type === 'info',
    }"
  >
    <AlertIcon
      v-if="flashMessage.type === 'error' || flashMessage.type === 'warning'"
      class="mt-1"
    />
    <div
      v-if="flashMessage.msg"
      class="flex flex-col space-y-3"
    >
      <div class="text-[0.813rem] font-semibold w-11/12 leading-4 max-w-lg min-w-fit mt-1">
        {{ t(flashMessage.msg) }}
      </div>
      <div
        v-if="flashMessage.btn"
        class="text-xs leading-5 font-medium text-center py-1.5 px-3 rounded-md cursor-pointer w-fit text-white transform hover:opacity-90"
        :class="{
          'bg-red-335': flashMessage.type === 'error',
          'bg-green-333': flashMessage.type === 'success',
          'bg-orange-333': flashMessage.type === 'warning',
          'bg-blue-333': flashMessage.type === 'info',
        }"
        @click="flashMessage.action"
      >
        {{ t(flashMessage.btn) }}
      </div>
    </div>
    <div
      class="cursor-pointer opacity-60 hover:opacity-80"
      @click="flashMessage.clear()"
    >
      <PlusIcon class="h-3 rotate-45" />
    </div>
  </TransitionRoot>
</template>
