<script lang="ts" setup>
import DisconnectIcon from '../Shared/Icon/DisconnectIcon.vue';
import useUserStore from '../../stores/user';
import { useConfigurationStore } from '../../stores/configuration';
import { useI18n } from '../../i18n';

const userStore = useUserStore();
const configurationStore = useConfigurationStore();
const { t } = useI18n();

</script>
<template>
  <div class="flex justify-between items-center pb-6 pt-3">
    <div class="flex space-x-3 px-3 py-1 items-center">
      <img
        :src="userStore.picture"
        :alt="userStore.name"
        class="cursor-pointer h-6 rounded-full"
      >
      <div
        v-if="configurationStore.leftDrawer"
        class="text-blue-335 flex flex-col leading-4"
      >
        <div class="text-sm font-medium leading-4 line-clamp-1 ">
          {{ userStore.name }}
        </div>
        <div class="text-xxxs opacity-50 font-normal leading-4">
          {{ userStore.email }}
        </div>
      </div>
    </div>
    <div
      v-if="configurationStore.leftDrawer"
      :title="t('disconnect')"
      class="group  cursor-pointer p-2 px-4 -mr-4"
      @click="userStore.logout"
    >
      <DisconnectIcon class="text-gray-334 group-hover:text-blue-333 " />
    </div>
  </div>
</template>
