<script setup lang="ts">
import {
  PropType, onMounted, ref, watch,
} from 'vue';

const props = defineProps({
  modelValue: [String, Boolean, Number],
  value: [String, Boolean, Number],
  options: Array as PropType<Array<{
    value?: string | number | boolean | null,
    name: string,
    disabled?: boolean
  }>>,
  icon: {
    type: String,
    required: false,
  },
  defaultValue: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(['update:modelValue', 'selected']);

const localValue = ref(props.modelValue);
watch(localValue, (value) => {
  emit('update:modelValue', value);
  emit('selected', value);
});

const localOptions = ref();
onMounted(() => {
  localOptions.value = props.options;
  if (props.defaultValue) localOptions.value.unshift({ name: props.defaultValue, value: undefined });
});
</script>

<template>
  <div class="relative inline-block">
    <fa
      :icon="['fas', 'caret-down']"
      class="inline absolute pointer-events-none text-gray-336 top-6 right-1 mr-2"
    />
    <select
      v-model="localValue"
      class="appearance-none w-full rounded-lg border border-gray-333 mt-2.5 text-sm leading-5 px-3 py-2.5 outline-0 focus:border-blue-333 focus:ring focus:ring-gray-329"
    >
      <option
        v-for="(option, key) in options"
        :key="key"
        :value="option.value"
        :disabled="option.disabled"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>
