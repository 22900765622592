import ChorusInvoiceSubmissionResponseMessage from './ChorusInvoiceSubmissionResponseMessage';

export default class ChorusInvoiceSubmissionResponse {
  public id!: string;

  public invoiceId!: string;

  public invoicingContext!: string;

  public message: ChorusInvoiceSubmissionResponseMessage = new ChorusInvoiceSubmissionResponseMessage();

  public paymentMethod!: string;

  public status: string = '';

  public taxType: string = '';
}
