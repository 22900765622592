import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faArrowLeftFromLine, faArrowDownShortWide, faArrowUpShortWide, faArrowDownArrowUp, faChevronDown,
} from '@fortawesome/pro-thin-svg-icons';
import {
  faSpinnerThird, faCheckCircle, faXmarkCircle, faCircleQuestion, faCaretDown, faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { faXmark, faCheck, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';

library.add(
  faMagnifyingGlass,
  faTriangleExclamation,
  faSpinnerThird,
  faCheckCircle,
  faXmarkCircle,
  faArrowLeftFromLine,
  faCircleQuestion,
  faArrowDownShortWide,
  faArrowUpShortWide,
  faArrowDownArrowUp,
  faCaretDown,
  faXmark,
  faCheck,
  faChevronDown,
);

export { library, FontAwesomeIcon };
