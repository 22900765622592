import {
  getAllPages, getAsync, postAsync, sleep,
} from '../apiHelper';
import ChorusInvoiceSubmissionResponse from '../../models/Chorus/ChorusInvoiceSubmissionResponse';
import ChorusInvoiceSubmissionRequest from '../../models/Chorus/ChorusInvoiceSubmissionRequest';
import { getSlug } from '../slugApi';
import {
  getDemoInvoiceList,
  setDemoInvoiceSubmission,
} from '../../store/Demo/DemoInvoiceList';

const endpoint = '/api/choruspro/invoice-submissions';

export async function submitInvoiceToChorusAsync(model: ChorusInvoiceSubmissionRequest): Promise<any> {
  if (getSlug() !== 'demo') {
    const result: any = await postAsync(endpoint, model);
    return result;
  }
  await sleep(300);
  const resp = new ChorusInvoiceSubmissionResponse();
  resp.status = 'ACCEPTED';
  setDemoInvoiceSubmission(
    getDemoInvoiceList.value.findIndex(
      (element) => element.id === model.invoiceId,
    ),
    resp,
  );
  return { invoiceId: '0' };
}

export async function getInvoiceSubmissionStatusAsync(
  pageNb: number,
  invoiceId: string,
): Promise<ChorusInvoiceSubmissionResponse> {
  if (getSlug() !== 'demo') {
    const result: any = await getAsync(
      `${endpoint}?page=${pageNb}&invoiceId=${invoiceId}&order[sendedAt]=desc`,
    );
    if (result['hydra:member'].length) return result['hydra:member'][0] as ChorusInvoiceSubmissionResponse;
    return new ChorusInvoiceSubmissionResponse();
  }
  await sleep(300);
  return new ChorusInvoiceSubmissionResponse();
}

export async function getInvoiceSubmissionStatusAsyncByIds(
  invoiceIds: Array<string>,
): Promise<Array<ChorusInvoiceSubmissionResponse>> {
  if (getSlug() !== 'demo') {
    let url = `${endpoint}?page=1&order[sendedAt]=asc`;
    invoiceIds.forEach((element) => {
      url += `&invoiceId[]=${element}`;
    });

    return await getAllPages(url, import.meta.env.VITE_BACKEND) as Array<ChorusInvoiceSubmissionResponse>;
  }
  await sleep(300);
  const result = [];
  for (let i = 0; i < invoiceIds.length; i += 1) {
    result.push(new ChorusInvoiceSubmissionResponse());
  }
  return result;
}
